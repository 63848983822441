<template lang="pug">
main

	display-header(:img="require('@/assets/img/real_site_images/resized/namak3.jpg')",headline="Food at its best!")
	
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	area-section(
		:img="require('@/assets/img/real_site_images/resized/namak1.jpg')",
		title="Namak",
		subtitle="The Ultimate Fine-Dine",
		description="From traditional flavours to mouth watering delights throughout the world, experience a variety of culinary excellence."

		icon="clock",
		detail="Timings: Everyday 07:00 - 23:00",
		)

	area-section(
		:img="require('@/assets/img/real_site_images/resized/tiqri1.jpg')",
		title="Tiqri",
		subtitle="24 Hours Open Cafeteria",
		description="Tiqri is known for serving regional fast food, beverages and dessert.",
		
		icon="clock",
		detail="Timings: Everyday 24 / 7",
		)

	area-section(
		:img="require('@/assets/img/real_site_images/resized/shamiana2.jpg')",
		title="Shamiana",
		subtitle="Open Air Restaurant",
		description="Serves specialty cuisines like middle eastern, Mediterranean and wood fire pizza under the open sky."

		icon="clock",
		detail="Timings: Everyday 18:00 - 24:00",
		
		)

	location
</template>

<script>

// Imports
import DisplayHeader from '../components/DisplayHeader.vue'
import AreaSection from '../components/XAreaSection.vue'
import Location from '../components/Location.vue'

// Vue Config
export default {
	 name: "",
  components: {DisplayHeader, AreaSection, Location},
};
</script>

<style>
</style>